@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../tree/style/index';
@import '../../checkbox/style/mixin';

@tree-select-prefix-cls: ~'@{ant-prefix}-tree-select';
@select-tree-prefix-cls: ~'@{ant-prefix}-select-tree';

.antCheckboxFn(@checkbox-prefix-cls: ~'@{select-tree-prefix-cls}-checkbox');

.@{tree-select-prefix-cls} {
  // ======================= Dropdown =======================
  &-dropdown {
    padding: @padding-xs (@padding-xs / 2) 0;

    // ======================== Tree ========================
    .@{select-tree-prefix-cls} {
      border-radius: 0;

      &-list-holder-inner {
        align-items: stretch;

        .@{select-tree-prefix-cls}-treenode {
          padding-bottom: @padding-xs;

          .@{select-tree-prefix-cls}-node-content-wrapper {
            flex: auto;
          }
        }
      }
    }
  }
}

// ========================== Tree ==========================
.antTreeFn(@select-tree-prefix-cls);
